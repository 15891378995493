.modal_loader {
    width: 40%;
    height: 400px;
    margin: 0 auto;
}


.modalloader {
    display: block;
    position: fixed;
    z-index: 9999;
    padding-top: 120px;
    padding-left: 120px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

/* .css-1f9mbug-MuiDataGrid-root .MuiDataGrid-cell {
    border-bottom: none !important;
}
.css-1f9mbug-MuiDataGrid-root .MuiDataGrid-row{
    max-height: 100px !important;
}
.css-1f9mbug-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
    max-height: 100px !important;
} */

.css-1f9mbug-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
    overflow: scroll !important;
}
.css-axafay-MuiDataGrid-virtualScroller{
    overflow-y: auto !important;
}

.multi-select {
    position: relative;
    width: 500px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
  }
  
  .selected-options {
    padding: 8px;
    background-color: #f9f9f9;
    border-radius: 4px;
  }
  
  .options-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    z-index: 1000;
    height: 260px;
    overflow: auto;
  }
  
  .option {
    padding: 8px;
    cursor: pointer;
    font-weight: 600;
  }
  
  .option.selected {
    /* background-color: #007bff; */
    /* background-color: rgba(25, 118, 210, 0.08); */
    /* background-color: #007bff;  */
    background-color: rgb(25 118 210 / 23%);
    color: black;
    border: 1px solid white;
  }
  
  .option:hover {
    background-color: #f1f1f1;
  }

  /* .field{
    border: 1px solid transparent;
    padding: 5px 10px;
    font-size: 12px;
    background-color: #808080c9;
    border-radius: 10px;
    color: white;
    font-weight: 800;
    margin-right: 5px;
} */
  